<template>
  <div>
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed pl-0"
                @click="collapsed=!collapsed" v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><div class="menu-btn-burger"></div></span>
        </button>
        <a href="https://elumiti.kz/" class="navbar-brand mob-hidden p-0">
          <!-- <img width="44" src="/images/logo_el_umiti.png"> -->
          <img width="88" src="/images/EA_logo_stamp.svg" style="padding: 7px">
        </a>
        <div class="d-flex flex-row align-items-center">
          <router-link to="/" class="navbar-brand p-0">
            <img width="88" src="/images/qabilet-white-logo.svg">
          </router-link>
          <a href="https://elumiti.kz/" class="navbar-brand navbar-brand--mob">
            <!-- <img width="44" src="/images/logo_el_umiti.png"> -->
            <img width="60" src="/images/EA_logo_stamp.svg">
          </a>
        </div>

        <a href="#" class="navbar-brand special-view-button-mobile">
          <img width="22" src="/images/special-view.svg">
        </a>

        <div class="navbar-collapse desktop-menu">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item hidden-md mx-0">
              <a class="nav-link pl-2 pr-2 border-bottom-0" href="https://www.instagram.com/elbasy_academy/"
                 target="_blank"><i class="ig-icon"></i></a>
            </li>
            <li class="nav-item mx-0">
              <a class="nav-link pl-2 pr-2 border-bottom-0" href="https://www.facebook.com/elumiti"
                 target="_blank"><i class="fb-icon"></i></a>
            </li>
            <li class="nav-item mx-0">
              <a class="nav-link pl-2 pr-2 border-bottom-0"
                 href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA" target="_blank"><i class="yt-icon"></i></a>
            </li>


            <li class="nav-item nav-link-phone">
              <a class="nav-link" href="tel:87273100258"><i class="phone-icon"></i>
                8 727 310 02 58
              </a>
              <span>{{ $t('navbar.call_is_paid') }}</span>
            </li>
            <li v-if="!logged" class="nav-item">
              <router-link tag="a" to="/signin" class="nav-link">{{ $t('navbar.signin') }}</router-link>
            </li>
            <li v-if="!logged" class="nav-item">
              <router-link tag="a" to="/signup" class="nav-link">{{ $t('navbar.signup') }}</router-link>
            </li>
            <li v-if="logged" class="nav-item">
              <a class="nav-link" @click="goToProfile()">{{ $t('navbar.profile') }}</a>
            </li>

            <li class="nav-item " :class="{'lang-active': $i18n.locale == 'ru'}">
              <a class="nav-link cursor-pointer" @click="switchLocale('ru')"><span>RU</span></a>
            </li>
            <li class="nav-item " :class="{'lang-active': $i18n.locale == 'kz'}">
              <a class="nav-link cursor-pointer" @click="switchLocale('kz')"><span>KZ</span></a>
            </li>
            <!--            <li class="nav-item " :class="{'lang-active': $i18n.locale == 'en'}">-->
            <!--              <a class="nav-link cursor-pointer" @click="switchLocale('en')"><span>EN</span></a>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
    </nav>
    <div class="nav-scroller" :class="{collapse: collapsed, show: !collapsed}">
      <div class="container">
        <!-- <button type="button" class="close d-block d-lg-none" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button> -->
        <nav class="nav justify-content-md-between">
          <li class="nav-item mx-0" :class="{'active': $route.path === '/'}">
            <router-link tag="a" class="nav-link" to="/">{{ $t('navbar.home') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/about'}">
            <router-link tag="a" class="nav-link" to="/about">{{ $t('navbar.about') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/for-members'}">
            <router-link tag="a" class="nav-link" to="/for-members">{{ $t('navbar.for-members') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/for-teachers'}">
            <router-link tag="a" class="nav-link" to="/for-teachers">{{ $t('navbar.for-teachers') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/for-parents'}">
            <router-link tag="a" class="nav-link" to="/for-parents">{{ $t('navbar.for-parents') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/regions'}">
            <router-link tag="a" class="nav-link" to="/regions">{{ $t('navbar.qabilet-centers') }}</router-link>
          </li>
          <li class="nav-item mx-0" :class="{'active': $route.path === '/faq'}">
            <router-link tag="a" class="nav-link" to="/faq">{{ $t('navbar.faq') }}</router-link>
          </li>
          <li class="nav-item mx-0 hidden-desktop nav-phone-number">
            <a class="nav-link" href="tel:87273100258"><i class="phone-icon"></i>8 727 310 02 58 ({{ $t('navbar.call_is_paid') }})</a>
          </li>
          <li v-if="!logged" class="nav-item hidden-desktop ">
            <router-link tag="a" to="/signin" class="nav-link" href="#">{{ $t('navbar.signin') }}</router-link>
          </li>
          <li v-if="!logged" class="nav-item hidden-desktop ">
            <router-link tag="a" to="/signup" class="nav-link">{{ $t('navbar.signup') }}</router-link>
          </li>
          <li v-if="logged" class="nav-item hidden-desktop ">
            <a href="/profile" class="nav-link">{{ $t('navbar.profile') }}</a>
          </li>
          <li v-if="logged" class="nav-item hidden-desktop ">
            <a @click.prevent="logoutMethod" style="cursor: pointer!important;"
               class="nav-link cursor-pointer">{{ $t('navbar.signout') }}</a>
          </li>

        </nav>
        <!--        <div class="nav-mobile-apps">-->
        <!--          <h5>{{ $t('navbar.install-app') }}</h5>-->
        <!--          <div class="mobile-apps-wrap">-->
        <!--            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>-->
        <!--            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <ul class="locale-links-mobile clearfix">
      <li class="nav-item mr-0" :class="{'lang-active': $i18n.locale == 'ru'}">
        <a class="nav-link cursor-pointer" @click="switchLocale('ru')"><span>RU</span></a>
      </li>
      <li class="nav-item mx-0" :class="{'lang-active': $i18n.locale == 'kz'}">
        <a class="nav-link cursor-pointer" @click="switchLocale('kz')"><span>KZ</span></a>
      </li>
      <!--      <li class="nav-item ml-0" :class="{'lang-active': $i18n.locale == 'en'}">-->
      <!--        <a class="nav-link cursor-pointer" @click="switchLocale('en')"><span>EN</span></a>-->
      <!--      </li>-->
    </ul>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      logged: false,
      collapsed: true
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
  },
  methods: {
    goToProfile() {
      if (localStorage.getItem('role') == 'student') {
        this.$router.push(this.$route.query.redirect || '/profile')
      }
      else if(localStorage.getItem('role') == 'center-coach'){
        this.$router.push(this.$route.query.redirect || '/profile-manager')
      }
      else {
        this.$router.push(this.$route.query.redirect || '/profile-parent')
      }

    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
}
</script>
<style scoped>
@media screen and (max-width: 991px) {
  .nav-item {
    padding-left: 15px;
  }
}
</style>
